require('./bootstrap');

/* initialize alpinejs*/
import Alpine from 'alpinejs'
 
window.Alpine = Alpine
 
Alpine.start() 

// Load slim-select
import SlimSelect from 'slim-select'

window.SlimSelect = SlimSelect

var usersSelect = document.getElementById("users-select");
if (usersSelect) {
    new SlimSelect({
        select: '#users-select'
    })
}

var handoverSelect = document.getElementById("handover-select");
if (handoverSelect) {
    new SlimSelect({
        select: '#handover-select'
    })
}

var txSelect = document.getElementById("tx-type-select");
if (handoverSelect) {
    new SlimSelect({
        select: '#tx-type-select'
    })
}



// Html5Qrcode
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'
window.Html5Qrcode = Html5Qrcode
window.Html5QrcodeSupportedFormats = Html5QrcodeSupportedFormats

// momentjs
import moment from 'moment';
import tz from 'moment-timezone';
window.moment = moment;
window.moment.tz = tz;


function parseFlatpickerDateRange() {
    document.querySelectorAll(".dropdown-date-preset").forEach(elm => {
        elm.addEventListener("click", e => {
            const el = e.currentTarget;
            const startDate = el.getAttribute("data-start-date");
            const endDate = el.getAttribute("data-end-date");
            const canSubmit = el.getAttribute("data-submit");
            const elStart = document.querySelectorAll(
                el.getAttribute("data-start-field")
            );
            const elEnd = document.querySelectorAll(
                el.getAttribute("data-end-field")
            );

            if (elStart) {
                elStart.forEach(eld => {
                    eld.value = startDate;
                    eld.dispatchEvent(new Event("change"));
                });
            } else {
                console.error(
                    "data-start-field not found",
                    el.getAttribute("data-start-field")
                );
            }

            //
            if (elEnd) {
                elEnd.forEach(eld => {
                    eld.value = endDate;
                    eld.dispatchEvent(new Event("change"));
                });
            } else {
                console.error(
                    "data-start-field not found",
                    el.getAttribute("data-end-field")
                );
            }

            if (canSubmit) {
                setTimeout(() => {
                    (elStart[0] || elEnd[0]).closest("form").submit();
                }, 100);
                
            }

            window?.handleTimeUpdate?.()

            
        });
    });
}

document.addEventListener("DOMContentLoaded", () => parseFlatpickerDateRange());